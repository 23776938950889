import { render, staticRenderFns } from "./Locations.vue?vue&type=template&id=ec89ac96&scoped=true&"
import script from "./Locations.vue?vue&type=script&lang=js&"
export * from "./Locations.vue?vue&type=script&lang=js&"
import style0 from "./Locations.vue?vue&type=style&index=0&id=ec89ac96&lang=scss&scoped=true&"
import style1 from "./Locations.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec89ac96",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RadarMap: require('/opt/build/repo/components/common/RadarMap.vue').default,IconMarkerGreen: require('/opt/build/repo/components/icons/IconMarkerGreen.vue').default,OrderTime: require('/opt/build/repo/components/common/OrderTime.vue').default,SavedAddresses: require('/opt/build/repo/components/locations/SavedAddresses.vue').default})
