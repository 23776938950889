//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Mapbox from 'mapbox-gl-vue'
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import stateList from '../../assets/src/usstates.json'
// import Radar from 'radar-sdk-js'

export default {
  // components: { Mapbox },
  data() {
    const isMobile = this.$mq === 'md'

    return {
      // accessToken: process.env.MAPBOX_TOKEN,
      // mapStyle: 'mapbox://styles/mapbox/outdoors-v11',
      handoff: 'curbside',
      mode: 'asap',
      mobileMap: isMobile,
      openStates: [],
      selectedLocation: false,
      query: '',
      // map: {},
      timeout: null,
      suggestions: [],
      searched: false,
      stateList: stateList,
      address: {
        address: '',
        address_2: '',
        city: '',
        state: '',
        zip: '',
        instructions: ''
      },
      saveAddress: false,
      center: [],
      locationSelected: false,
      time: '',
      autocompleteDeliveryCoordinates: null,
      addressLabel: '',
      isLocationOpen: false,
    }
  },
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    if (this.order && this.order.handoff) {
      this.handoff = this.order.handoff
    }
    // If switching to delivery show the delivery form
    if (this.$route.query.address) {
      this.query = this.$route.query.address
      this.search()
    } else if (this.$route.query.delivery) {
      this.handoff = 'dispatch'
    } else {
      if (this.location) {
        this.locationSelected = true
        this.selectedLocation = this.location
      }
    }

    // Initialize Radar SDK
    this.$radar.initialize(process.env.RADAR_KEY)

    // Create autocomplete widgets
    this.$radar.ui.autocomplete({
      container: 'autocomplete', // OR document.getElementById('autocomplete')
      responsive: true,
      placeholder: 'Enter Your Address or Zipcode',
      countryCode: 'US',
      onSelection: (result) => {
        this.query = result.addressLabel
        this.search()
      },
    })
    this.$radar.ui.autocomplete({
      container: 'autocomplete-delivery',
      responsive: true,
      placeholder: '',
      countryCode: 'US',
      onSelection: (result) => {
        document.querySelector('#autocomplete-delivery input').value = result.addressLabel || result.placeLabel || ""
        this.address.address = result.addressLabel || result.placeLabel || ""
        this.address.city = result.city
        this.address.state = result.stateCode
        this.address.zip = result.postalCode
        this.addressLabel = result.addressLabel

        this.autocompleteDeliveryCoordinates = {
          lat: result.latitude,
          lng: result.longitude
        }
      },
    })

    // Add autofill functionality
    this.$nextTick(() => {
      document.addEventListener('keyup', (e) => {
        if (e.target.parentElement.parentElement.id == 'autocomplete') {
          this.query = e.target.value
        }
      })
      document.addEventListener('keyup', (e) => {
        if (e.target.parentElement.parentElement.id == 'autocomplete-delivery') {
          this.address.address = e.target.value
        }
      })
    })

    if (this.user) {
      this.$api.getAddresses()
    }

    if (this.location) {
      this.isLocationOpen = this.location.currently_open

      if (this.isLocationOpen) {
        if (this.mode !== "later") {
          this.mode = 'asap'
        }
      } else {
        this.mode = 'later'
      }
    }
  },
  methods: {
    ...mapActions([ 'locationSearch' ]),
    geoLocate() {
      if(navigator.geolocation) {
        this.$store.commit('setLoading', true)
        navigator.geolocation.getCurrentPosition(this.getCoordinates, this.getCoordinatesError)
      } else {
        this.$store.commit('setErrors', ['Please enable location services and try again.'])
      }
    },
    getCoordinates(position) {
      let coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      this.$api.findLocations(coords)
    },
    getCoordinatesError() {
      this.$store.commit('setLoading', false)
      this.$store.commit('setErrors', ['We couldn’t locate you. Please enter your address.'])
    },
    setHandoff(mode) {
      this.handoff = mode
    },
    setMode(mode) {
      this.mode = mode
    },
    toggleState(i) {
      if (this.openStates.includes(i)) {
        let index = this.openStates.indexOf(i)
        this.openStates.splice(index, 1)
      } else {
        this.openStates.push(i)
      }
    },
    openStatus(location) {
      let dayOfWeek = parseInt(moment().format('e'))
      let hours = location.hours[dayOfWeek]
      if (hours !== undefined) {
        let open = moment(hours.open,'HH:mm')
        let close = moment(hours.close,'HH:mm')
        let now = moment()
        let isOpen = now > open && now < close
        let tomorrow = dayOfWeek // today
        if (tomorrow == 7) { // if Sunday
          tomorrow = 0
        } else {
          tomorrow = dayOfWeek
        }
        let tomorrowHours = location.hours[tomorrow]
        let tomorrowOpen = location.hours[tomorrow].open
        let tomorrowClose = location.hours[tomorrow].close
        let tomorrowString = moment(tomorrow,'e').format('ddd')
        if (moment(close).format('h:mm A') == '12:00 AM' && moment(open).format('h:mm A') == '12:00 AM' && hours.holiday == null) {
          return 'Open 24 hours'
        } else if (moment(close).format('h:mm A') == '12:00 AM' && hours.holiday == null) {
          return 'Open until midnight'
        } else if (isOpen & hours.holiday == null) {
          return 'Open until '+moment(close).format('h:mm A')
        } else if (moment(open).diff(moment(now)) > 0 && hours.holiday == null) { // not open yet today
          return 'Opens at '+moment(open).format('h:mm A')
        } else if (tomorrowHours.holiday == null) { // opens tomorrow and not holiday tomorrow
          return 'Opens tomorrow at '+moment(tomorrowOpen,'HH:mm').format('h:mm A')
        } else { // closed and holiday tomorrow
          return 'Closed for holiday'
        }
      } else {
        return 'Closed'
      }
    },
    async startOrder(l) {
      let location = await this.$api.getLocation(l.id)
      this.$store.commit('setLocation', location)
      if (!this.locationSelected) {
        this.locationSelected = true
      } else {
        await this.$api.getMenu()
        let order = null
        if(this.order && this.order.menu_id != location.menu_id) {
          order = await this.$api.transferOrder()
        } else {
          order = await this.$api.createOrder()
        }
        await this.$api.setHandoff(this.handoff)
        // Set delivery time
        if (this.mode !== 'asap') {
          if (this.time) {
            await this.$api.setTime(this.time)
          }
        }
        if(order) {
          this.$router.push('/order/')
        }
      }
    },
    async setDeliveryTime() {
      if (this.mode !== 'asap') {
        if (this.time) {
          await this.$api.setTime(this.time)
        }
      }
      this.$router.push('/order/')
    },
    selectLocation(location) {
      this.selectedLocation = location
      this.$refs.map.setActiveLocation(location)
      // this.map.flyTo({
      //   center: [this.selectedLocation.lng,this.selectedLocation.lat],
      //   zoom: 14
      // })
    },
    clickPopup(id) {
      this.selectedLocation = this.allLocations.find(x => x.id === id)
    },
    toggleMap(bool) {
      this.mobileMap = bool
      this.$nextTick(() => {
        window.dispatchEvent(new CustomEvent('resize'))
      })
    },
    async search() {
      await this.locationSearch({ address: this.query })
      if (this.locationList) {
        // this.map.flyTo({
        //   center: [this.locationList[0].lng,this.locationList[0].lat],
        //   zoom: 8
        // })
      }
      this.searched = true
    },
    async deliverySearch() {
      let errors = []
      if (!this.address.address) {
        errors.push('Address is required.')
      }
      if (!this.address.city) {
        errors.push('City is required.')
      }
      if (!this.address.zip) {
        errors.push('Zipcode is required.')
      }
      if (errors.length == 0) {
        await this.checkDeliveryLocations()
      } else {
        this.$store.commit('setErrors', errors)
      }
    },
    async checkDeliveryLocations() {
      let search = null

      if (this.coordinates) {
        search = await this.$api.findLocations(this.autocompleteDeliveryCoordinates)
      } else {
        search = await this.$api.findLocations({ address: `${this.address.address}, ${this.address.city}, ${this.address.zip}` })

        // if no locations found, try searching by addressLabel, because some locations are found by address (formattedAddress) and some by addressLabel
        if (search && !search.locations.length) {
          search = await this.$api.findLocations({ address: this.addressLabel })
        }
      }

      if (search && search.locations.length > 0) {
        this.$store.commit('setLocation', search.locations[0])
        this.locationSelected = true
      } else {
        this.$store.commit('setErrors', ['No nearby locations found.'])
      }
    },
    async processDeliveryLocation() {
      const requestData = { ...this.address }
      if (this.mode === 'later') {
        requestData.time = this.time
      }

      const request = await this.$api.findDeliveryLocations(requestData)

      if (!request || (request.location && request.location.length < 1)) {
        this.invalidAddress = true
        this.$store.commit('setErrors', ['Sorry! No delivery services are available to handle this order at this time.'])
      } else {
        let location = await this.$api.getLocation(request.id)
        this.$store.commit('setLocation', location)
        await this.$api.getMenu()
        this.locationSelected = true
        this.startDeliveryOrder(location)
      }
    },
    async startDeliveryOrder(location) {
      let order = null
      if (this.order && this.order.menu_id != location.menu_id) {
        order = await this.$api.transferOrder()
      } else {
        order = await this.$api.createOrder()
      }
      if (order) {
        if (this.address?.id) {
          order = await this.$api.setAddress({
            id: this.address.id,
            save: false,
          })
        } else {
          order = await this.$api.setAddress({
            address: this.address.address,
            building: this.address.address_2,
            city: this.address.city,
            zip: this.address.zip,
            state: this.address.state,
            instructions: this.address.instructions,
            save: this.saveAddress,
          })
        }
        await this.$api.setHandoff(this.handoff)
        this.$store.commit('setOrder', order)

        if (this.mode !== 'asap') {
          if (this.time) {
            await this.$api.setTime(this.time)
          }
        }

        this.$router.push('/order/')
      }
    },
    async orderWithSavedAddress(address) {
      this.address = address

      await this.processDeliveryLocation()
    },
    setTime(time) {
      this.time = time
    },
  },
  computed: {
    ...mapState(['locationList', 'options', 'location', 'allLocations', 'loading', 'order', 'user', 'menu']),
    locations() {
      return this.locationList || this.allLocations
    },
    mq() {
      return this.$mq
    },
    states() {
      let states = new Object()
      states['Alabama'] = []
      states['Alaska'] = []
      states['Arizona'] = []
      states['Arkansas'] = []
      states['California'] = []
      states['Colorado'] = []
      states['Connecticut'] = []
      states['Delaware'] = []
      states['Florida'] = []
      states['Georgia'] = []
      states['Hawaii'] = []
      states['Idaho'] = []
      states['Illinois'] = []
      states['Indiana'] = []
      states['Iowa'] = []
      states['Kansas'] = []
      states['Kentucky'] = []
      states['Louisiana'] = []
      states['Maine'] = []
      states['Maryland'] = []
      states['Massachusetts'] = []
      states['Michigan'] = []
      states['Minnesota'] = []
      states['Mississippi'] = []
      states['Missouri'] = []
      states['Montana'] = []
      states['Nebraska'] = []
      states['Nevada'] = []
      states['New Hampshire'] = []
      states['New Jersey'] = []
      states['New Mexico'] = []
      states['New York'] = []
      states['North Carolina'] = []
      states['North Dakota'] = []
      states['Ohio'] = []
      states['Oklahoma'] = []
      states['Oregon'] = []
      states['Pennsylvania'] = []
      states['Rhode Island'] = []
      states['South Carolina'] = []
      states['South Dakota'] = []
      states['Tennessee'] = []
      states['Texas'] = []
      states['Utah'] = []
      states['Vermont'] = []
      states['Virginia'] = []
      states['Washington'] = []
      states['Washington DC'] = []
      states['West Virginia'] = []
      states['Wisconsin'] = []
      states['Wyoming'] = []
      for (let i = this.allLocations.length - 1; i >= 0; i--) {
        if (!this.allLocations[i].available) {
          continue
        }
        if (this.allLocations[i].state == 'AK') {
          states['Alaska'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'AL') {
          states['Alabama'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'AR') {
          states['Arkansas'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'AZ') {
          states['Arizona'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'CA') {
          states['California'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'CO') {
          states['Colorado'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'CT') {
          states['Connecticut'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'DE') {
          states['Delaware'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'FL') {
          states['Florida'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'GA') {
          states['Georgia'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'HI') {
          states['Hawaii'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'IA') {
          states['Iowa'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'ID') {
          states['Idaho'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'IL') {
          states['Illinois'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'IN') {
          states['Indiana'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'KS') {
          states['Kansas'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'KY') {
          states['Kentucky'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'LA') {
          states['Louisiana'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MA') {
          states['Massachusetts'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MD') {
          states['Maryland'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'ME') {
          states['Maine'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MI') {
          states['Michigan'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MN') {
          states['Minnesota'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MO') {
          states['Missouri'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MS') {
          states['Mississippi'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MT') {
          states['Montana'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NC') {
          states['North Carolina'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'ND') {
          states['North Dakota'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NE') {
          states['Nebraska'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NH') {
          states['New Hampshire'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NJ') {
          states['New Jersey'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NM') {
          states['New Mexico'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NV') {
          states['Nevada'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NY') {
          states['New York'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'OH') {
          states['Ohio'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'OK') {
          states['Oklahoma'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'OR') {
          states['Oregon'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'PA') {
          states['Pennsylvania'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'RI') {
          states['Rhode Island'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'SC') {
          states['South Carolina'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'SD') {
          states['South Dakota'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'TN') {
          states['Tennessee'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'TX') {
          states['Texas'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'UT') {
          states['Utah'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'VA') {
          states['Virginia'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'VT') {
          states['Vermont'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'WA') {
          states['Washington'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'DC') {
          states['Washington DC'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'WI') {
          states['Wisconsin'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'WV') {
          states['West Virginia'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'WY') {
          states['Wyoming'].push(this.allLocations[i])
        }
      }
      return states
    },
    statesWithLocations() {
      const filteredStates = new Object()
      for (let state in this.states) {
        if (this.states[state].length > 0) {
          filteredStates[state] = this.states[state]
        }
      }
      return filteredStates
    }
  },
  watch: {
    location() {
      if (!this.location) return

      this.isLocationOpen = this.location.currently_open

      if (this.isLocationOpen) {
        if (this.mode !== "later") {
          this.mode = 'asap'
        }
      } else {
        this.mode = 'later'
      }
    }
  }
}
